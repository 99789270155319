import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './AvatarCard.css';

interface ParticipantCourse {
  id: number;
  name: string;
  xpPerLevel: number;
}

interface AvatarCardProps {
  avatar: string;
  avatarName: string | null;
  course: ParticipantCourse;
  xp: number;
  gold: number;
}

const AvatarCard: React.FC<AvatarCardProps> = ({ avatar, avatarName, course, xp, gold }) => {
  const { t } = useTranslation('global');

  return (
    <Link to={`/courses/${course.id}`}>
      <div className='col text-center'>
        <div className='wrapper'>
          <div className='clash-card wizard'>
            <div className='clash-card__image clash-card__image--wizard'>
              {avatar ? (
                <img
                  src={`${process.env.PUBLIC_URL}/${avatar}`}
                  alt='avatar'
                  className='avatar-image'
                  style={{
                    objectFit: 'contain',
                    width: 'auto',
                    height: '100%',
                  }}
                />
              ) : (
                <div
                  className='d-flex align-items-center justify-content-center'
                  style={{ height: '100%' }}
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{
                      objectFit: 'contain',
                      width: 'auto',
                      height: '80%',
                      color: 'white',
                    }}
                  />
                </div>
              )}
            </div>
            <div className='clash-card__unit-name'>
              {avatarName ? avatarName : t('selectAvatarCard')}
              <span>
                {t('course')}: {course.name}
              </span>
            </div>
            <div className='clash-card__unit-stats clash-card__unit-stats--wizard clearfix'>
              <div className='one-third'>
                <div className='stat'>{Math.floor(xp / course.xpPerLevel) + 1}</div>
                <div className='stat-value'>{t('level')}</div>
              </div>

              <div className='one-third'>
                <div className='stat'>{xp}</div>
                <div className='stat-value'>{t('XP')}</div>
              </div>

              <div className='one-third no-border'>
                <div className='stat'>{gold}</div>
                <div className='stat-value'>
                  <img
                    src={`${process.env.PUBLIC_URL}/gold-coins.png`}
                    alt='Or Icon'
                    className='icon-image'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default AvatarCard;
