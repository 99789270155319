import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { CreatedResponse, fetchAPI } from '../../utils/httpRequests';
import { useState } from 'react';

interface GiveRewardsModalProps {
  isOpen: boolean;
  onClose: () => void;
  pointsType: 'xp' | 'gold' | 'life';
  participantList: number[];
}

interface GiveRewardsBody {
  xp: number;
  gold: number;
  life: number;
  comments: string;
  participantIds: number[];
  amount: number;
}

const GiveRewardsModal = ({
  isOpen,
  onClose,
  pointsType,
  participantList,
}: GiveRewardsModalProps) => {
  const { t } = useTranslation('global');
  const [successMessage, setSuccessMessage] = useState(''); // Contains the success or error message
  const [giveMode, setGiveMode] = useState(true); // Give points when true, remove points when false

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<GiveRewardsBody>();

  // Reset values of the modal, refetch queries and close the modal
  const closeModal = async (): Promise<void> => {
    reset();
    setSuccessMessage('');
    setGiveMode(true);
    onClose();
  };

  const giveRewards = async (body: GiveRewardsBody): Promise<void> => {
    body.participantIds = participantList;
    body.xp = 0;
    body.gold = 0;
    body.life = 0;

    if (!giveMode) {
      body.amount = -body.amount;
    }

    body[pointsType] = body.amount;

    const response = await fetchAPI<CreatedResponse[]>('/rewards', {
      method: 'POST',
      body,
    });

    if (response.isSuccess) {
      setSuccessMessage(giveMode ? t('giveRewardsSuccess') : t('removeRewardsSuccess'));
    } else {
      // Set the error message to display
      setSuccessMessage(t('unexpectedError'));
    }
  };

  return (
    <>
      {isOpen && (
        <div className='modal-backdrop show' style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}></div>
      )}
      <div
        className={`modal ${isOpen ? 'show' : ''}`}
        tabIndex={-1}
        role='dialog'
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                {giveMode ? t(`give.${pointsType}`) : t(`remove.${pointsType}`)}
              </h5>
              <button
                type='button'
                className='btn btn-close'
                onClick={closeModal}
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              {successMessage ? (
                <div>
                  <div
                    className={
                      successMessage !== t('unexpectedError')
                        ? 'alert alert-success'
                        : 'alert alert-danger'
                    }
                    role='alert'
                  >
                    {successMessage}
                  </div>
                  <div className='modal-footer mt-3'>
                    <button type='button' className='btn btn-secondary' onClick={closeModal}>
                      {t('close')}
                    </button>
                  </div>
                </div>
              ) : (
                <form onSubmit={handleSubmit(giveRewards)}>
                  <div className='form-check form-switch d-flex align-items-center'>
                    <label className='form-check-label'>{t('removeRewards')}</label>
                    <input
                      className='form-check-input mx-2'
                      type='checkbox'
                      checked={giveMode}
                      onChange={() => setGiveMode(!giveMode)}
                    />
                    <label className='form-check-label'>{t('giveRewards')}</label>
                  </div>
                  <div>
                    <label htmlFor='amount'>{t(`amount.${pointsType}`)}</label>
                    <div className='input-group'>
                      <div className='input-group-text'>{giveMode ? '+' : '-'}</div>
                      <input
                        type='number'
                        defaultValue={0}
                        min={0}
                        {...register('amount', {
                          required: t('nullAmountError'),
                          valueAsNumber: true,
                          validate: (value) => value > 0 || t('nullAmountError'),
                        })}
                        className={`form-control ${errors.amount ? 'is-invalid' : ''}`}
                      />
                      {errors.amount && (
                        <div className='invalid-feedback'>{errors.amount.message}</div>
                      )}
                    </div>
                  </div>
                  <div>
                    <label htmlFor='comments' className='pt-2'>
                      {t('comments')}
                    </label>
                    <textarea {...register('comments')} className='form-control' rows={3} />
                  </div>
                  <div className='modal-footer mt-3'>
                    <button
                      type='submit'
                      className={giveMode ? 'btn btn-success' : 'btn btn-danger'}
                    >
                      {giveMode ? t('giveRewards') : t('removeRewards')}
                    </button>
                    <button type='button' className='btn btn-secondary' onClick={closeModal}>
                      {t('close')}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiveRewardsModal;
