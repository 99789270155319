import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Course } from '../quests/Quests';
import './CourseCard.css';

const CourseCard: React.FC<Course> = ({ id, name, accessCodeBlocked, accessCode }) => {
  const { t } = useTranslation('global');

  return (
    <div className='card bg-c-blue order-card'>
      <Link className='card-block link-style' to={`/courses/${id}`}>
        <h6 className='m-b-20'>{name}</h6>
        {accessCodeBlocked ? (
          <div>{t('courseBlocked')}</div>
        ) : (
          <p className='m-b-0'>
            {t('accessCode')}
            <span>{accessCode}</span>
          </p>
        )}
      </Link>
    </div>
  );
};

export default CourseCard;
