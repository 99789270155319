import { useEffect, useState } from 'react'; // Import useState
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchCourse, updateCourse } from '../../../utils/httpRequests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from 'react-tooltip';
import Loading from '../../../components/Loading';

const Settings = () => {
  const { t } = useTranslation('global');
  const { courseId } = useParams<{ courseId: string }>();
  const [accessCodeCopied, setAccessCodeCopied] = useState(t('copyAccessCode'));
  const [isChecked, setIsChecked] = useState(true);
  const {
    data: course,
    isLoading,
    refetch,
  } = useQuery(['course', courseId], () => fetchCourse(courseId));
  const minXpPerLevel = 500;
  const maxXpPerLevel = 10000;
  const xpPerLevelSteps = 500;
  const [xpPerLevel, setXpPerLevel] = useState(minXpPerLevel);
  const [isXpSame, setIsXpSame] = useState(true);
  const [xpChangeSuccess, setXpChangeSuccess] = useState(false);

  // Handle toggle change
  const handleToggle = async () => {
    if (!course) {
      return;
    }

    if (course?.accessCodeBlocked === !isChecked) {
      course.accessCodeBlocked = !course?.accessCodeBlocked;
    }
    setIsChecked(!isChecked);

    // Update course
    await updateCourse(courseId, {
      name: course.name,
      accessCode: course.accessCode,
      accessCodeBlocked: course.accessCodeBlocked, // New Value
      xpPerLevel: course.xpPerLevel,
    });
  };

  //   Copies generated password to clipboard
  const copyToClipboard = () => {
    if (!course) {
      return;
    }
    navigator.clipboard.writeText(course.accessCode);
    navigator.clipboard.readText();
    setAccessCodeCopied(t('accessCodeCopied'));
  };

  useEffect(() => {
    if (course?.accessCodeBlocked !== undefined) {
      setIsChecked(!course.accessCodeBlocked); // Set to false if blocked, true if not blocked
    }

    if (course?.xpPerLevel) {
      setXpPerLevel(course.xpPerLevel);
    }
  }, [course]);

  const handleXpSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!course) {
      return;
    }

    setXpPerLevel(Number(event.target.value));

    if (xpChangeSuccess) {
      setXpChangeSuccess(false);
    }

    if (Number(event.target.value) !== course.xpPerLevel) {
      setIsXpSame(false);
    } else {
      setIsXpSame(true);
    }
  };

  const handleSubmitXpPerLevel = async () => {
    if (!course) {
      return;
    }

    // Update course
    await updateCourse(courseId, {
      name: course.name,
      accessCode: course.accessCode,
      accessCodeBlocked: course.accessCodeBlocked,
      xpPerLevel: xpPerLevel!, // New Value
    });

    setIsXpSame(true);
    setXpChangeSuccess(true);
    await refetch();
  };

  return (
    <div className='m-3'>
      <h1>{t('settings')}</h1>

      {/* Check if the course is still loading */}
      {isLoading ? (
        <Loading insideModal={'default'} />
      ) : (
        <div>
          <div className='shadow p-3 mb-5 bg-white rounded'>
            <div className='d-flex justify-content-between align-items-center'>
              <h2>{t('accessCode') + ' ' + course?.accessCode}</h2>
              <div>
                <a data-tooltip-id='my-tooltip' data-tooltip-content={accessCodeCopied}>
                  <FontAwesomeIcon
                    icon={faCopy}
                    size='2x'
                    style={{ cursor: 'pointer' }}
                    onClick={copyToClipboard}
                  />
                </a>
                <Tooltip id='my-tooltip' />
              </div>
            </div>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckChecked'>
                {isChecked ? t('accessCodeActivated') : t('accessCodeBlocked')}
              </label>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckChecked'
                checked={isChecked}
                onChange={handleToggle}
              />
            </div>
          </div>
          <div className='shadow p-3 mb-5 bg-white rounded'>
            <div className='d-flex justify-content-between align-items-center'>
              <h3>{t('updateXpPerLevel')}</h3>
            </div>
            <div className='d-flex align-items-center'>
              <input
                type='range'
                className='form-range'
                min={minXpPerLevel}
                max={maxXpPerLevel}
                step={xpPerLevelSteps}
                value={xpPerLevel}
                onChange={handleXpSliderChange}
              />
              <span
                className='input-group-text ms-2 d-flex justify-content-center align-items-center'
                style={{ width: 70 }}
              >
                {xpPerLevel} XP
              </span>
            </div>
            <div className='d-flex align-items-center'>
              <button
                className='btn btn-primary py-2'
                onClick={handleSubmitXpPerLevel}
                disabled={isXpSame}
              >
                {t('update')}
              </button>
              {!isXpSame && (
                <div className='alert alert-warning ms-3 my-0 py-2'>{t('xpChangeWarning')}</div>
              )}
              {xpChangeSuccess && (
                <div className='alert alert-success ms-3 my-0 py-2'>{t('xpChangeSuccess')}</div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
