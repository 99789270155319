import { useQuery } from 'react-query';
import { fetchAPI } from '../../utils/httpRequests';
import { Course } from '../teacher/quests/Quests';
import { useAuth } from '../../utils/stores';
import { useTranslation } from 'react-i18next';
import AvatarCard from '../student/cards/AvatarCard';
import CourseCard from '../teacher/cards/CourseCard';

interface ParticipantCourse {
  id: number;
  name: string;
  xpPerLevel: number;
}

interface Avatar {
  avatar: string;
  avatarName: string | null;
  code: string;
  id: number;
  role: string;
  course: ParticipantCourse;
  xp: number;
  gold: number;
  life: number;
}

const Welcome = () => {
  const { t } = useTranslation('global');
  const { user, signedIn } = useAuth();

  const fetchAvatars = async (): Promise<Avatar[] | null> => {
    const avatars = await fetchAPI<Avatar[]>(`/participants`);
    if (avatars.isSuccess) {
      return avatars.data;
    } else {
      return null;
    }
  };

  // Function to get all Classes of a
  const fetchClasses = async (): Promise<Course[] | null> => {
    const classes = await fetchAPI<Course[]>('/courses');
    if (classes.isSuccess) {
      return classes.data;
    } else {
      return null;
    }
  };

  const { data: classes, isLoading: classesLoading } = useQuery('courses', fetchClasses);
  const { data: avatars, isLoading: avatarsLoading } = useQuery('participants', fetchAvatars);
  return (
    <>
      <div data-testid='welcome'>
        {classesLoading || avatarsLoading ? (
          <div className='px-md-5'>
            <div className='flex align-items-center'>
              {t('loading')}
              <div className='me-2 spinner-border' role='status'>
                <span className='visually-hidden'>{t('loading')}</span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {/* For logged in teacher, will show card for each class */}
            {signedIn && user?.teacher ? (
              <div className='container'>
                {classes && classes.length > 0 ? (
                  <div className='row mw-100'>
                    {classes?.map((course) => (
                      <div className='col-md-4 col-xl-3' key={course.id}>
                        <CourseCard
                          id={course.id}
                          name={course.name}
                          accessCodeBlocked={course.accessCodeBlocked}
                          accessCode={course.accessCode}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className='container mt-3'>
                    <h5>{t('noClassesMessage')}</h5>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {avatars && avatars.length > 0 ? (
                  <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 mw-100'>
                    {/* For logged in student, will show card for each class */}
                    {avatars?.map((avatar) => (
                      <AvatarCard
                        key={avatar.id}
                        avatar={avatar.avatar}
                        avatarName={avatar.avatarName}
                        course={avatar.course}
                        xp={avatar.xp}
                        gold={avatar.gold}
                      />
                    ))}
                  </div>
                ) : (
                  <div className='container mt-3'>
                    <h5>{t('noAvatarsMessage')}</h5>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Welcome;
